import { Button, Container, Heading, Text } from '@chakra-ui/react';
import React from "react";
import PageLayout from "../components/layout/PageLayout";
import GLink from '../lib/GLink';

const Guide = () => {
  return (
    <PageLayout>
      <Container>
        <Heading py={4}>Guía de Uso</Heading>
        <Text fontSize={10}>
          Sus datos no serán proveídos a nadie fuera de Residencial La Hacienda
        </Text>
        <Text mt={10}>
          Para utilizar esta aplicación, debe tener una cuenta de usuario. Si no tiene una cuenta, puede crearla en el siguiente enlace:
        </Text>
        <GLink to="/app">
          <Button
          variant={'link'}
          colorScheme={'green'}
          rounded={'full'}
          px={6}
          _hover={{
            bg: 'green.500',
          }}>
              {' '}
            Empezar ahora
          </Button>
        </GLink>
        <Text mt={10}>
          Una vez que se haya registrados, puede ingresar a la aplicación con su usuario y contraseña.
          Adicionalmente, un miembro de la junta directiva deberá corroborar su identidad antes poder acceder a la información.
        </Text>
        <Text mt={10}>
          Por favor contacte al administrador del sistema para obtener más información.
        </Text>
      </Container>
    </PageLayout>
  )
}
export default Guide;